import clsx from 'clsx';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  QuickLinks,
  Search,
} from '../../../partials';
import { useLayout } from '../../core';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#ED3237'),
  backgroundColor: '#ED3237',
  '&:hover': {
    backgroundColor: '#ED5237',
  },
  '&:active': {
    backgroundColor: '#ED3237',
  },
}));

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1';

const Topbar: FC = () => {
  const { config } = useLayout();

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <div className='px-5'>
          <Link to='/logout' className='px-5'>
            <ColorButton type='button' className='btn'>
              <span className='indicator-label'>Sair</span>
            </ColorButton>
          </Link>
        </div>
      </div>
      {/* end::User */}
    </div>
  );
};

export { Topbar };
