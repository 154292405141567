import { Navigate, Route, Routes } from 'react-router-dom';
import { MasterLayout } from '../_metronic/layout/MasterLayout';
import PaginaHome from '../app/Home/home';

function RotasPrivadas() {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='login' element={<Navigate to='/inicio' />} />
        {/* Pages */}
        <Route path='inicio' element={<PaginaHome />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/inicio' />} />
      </Route>
    </Routes>
  );
}

export default RotasPrivadas;
