import axios from 'axios';
import fileDownload from 'js-file-download';
import fileReader from './fileReader';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

const API_URL = process.env.REACT_APP_API_URL || 'api';
const API_SERVICOS_SOLUCAO = process.env.REACT_APP_API_URL_SOLUCAO || 'api';

export const LOGIN_URL = API_URL.concat('/financeiro/login');
export const CONSULTA_URL = API_URL.concat('/financeiro/consulta');
export const VISUALIZAR_URL = API_URL.concat('/financeiro/visualizar');
export const INSERIRLOG_URL = API_URL.concat('/financeiro/log');
export const VERIFICA_DEBITO_URL = API_URL.concat('/financeiro/VerificaDebito');
export const GERAR_QRCODE_PIX_SOLUCAO_URL = API_URL.concat(
  '/financeiro/GerarQrCodePIXSolucao'
);
export const CONSULTAR_PAGAMENTO_QRCODE_PIX_URL = API_URL.concat(
  '/financeiro/ConsultarPagamentoQRCodePIX'
);
export const VERIFICA_PAGAMENTO_PIX_URL = API_URL.concat(
  '/financeiro/VerificaPagamentoPIX'
);

const api = axios.create({
  baseURL: API_URL,
});

function apenasNumeros(valor: string) {
  const str = valor || '';
  return str.toString().replace(/\D+/g, '');
}

function mostraErro(texto: string) {
  const Alerta = withReactContent(Swal);
  return Alerta.fire({
    title: <p>Erro</p>,
    text: texto,
    icon: 'error',
  });
}

//retorna True caso estiver Bloqueado
function processarRespostaVerificaDebito(data) {
  const ds_mensagem = data.ds_mensagem.toUpperCase();
  return ds_mensagem === 'BLOQUEAR';
}

export function getDtIni() {
  var my_date = new Date().setDate(new Date().getMonth() - 1);
  return new Date(
    new Date(my_date).getFullYear(),
    new Date(my_date).getMonth(),
    1
  );
}

export function getDtFim() {
  var my_date = new Date().setDate(new Date().getMonth() - 1);
  return new Date(
    new Date(my_date).getFullYear(),
    new Date(my_date).getMonth() + 1,
    0
  );
}

export const useApi = () => ({
  login: async (usuario) => {
    const response = await api.post(LOGIN_URL, { usuario });
    return response.data;
  },
  consulta: async (NR_CPFCNPJ: string) => {
    const params = {
      nr_cpfcnpj: apenasNumeros(NR_CPFCNPJ),
    };
    const response = await api.get(CONSULTA_URL, { params });
    return response.data;
  },
  verificaDebito: async (NR_CPFCNPJ: string) => {
    try {
      const res = await api.get(
        VERIFICA_DEBITO_URL + '/' + apenasNumeros(NR_CPFCNPJ)
      );

      // Verifica se res.data é um array ou um objeto antes de processar
      const dados = Array.isArray(res.data) ? res.data[0] : res.data;
      const dadosProcessados = processarRespostaVerificaDebito(dados);
      return dadosProcessados;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  GerarQRCodePIX: async (CD_PRL: string) => {
    try {
      const res = await api.get(
        GERAR_QRCODE_PIX_SOLUCAO_URL + '/' + apenasNumeros(CD_PRL)
      );
      // Verifica se res.data é um array ou um objeto antes de processar
      const dados = Array.isArray(res.data) ? res.data[0] : res.data;
      return dados;
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  ConsultarPagamentoQRCodePIX: async (CD_PRL: string) => {
    try {
      const res = await api.get(
        CONSULTAR_PAGAMENTO_QRCODE_PIX_URL + '/' + apenasNumeros(CD_PRL)
      );
      // Verifica se res.data é um array ou um objeto antes de processar
      const dados = Array.isArray(res.data) ? res.data[0] : res.data;
      return dados;
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  VerificaPagamentoPIX: async (DS_ATIVO: string) => {
    try {
      const res = await api.post(
        VERIFICA_PAGAMENTO_PIX_URL + '?ds_ativo=' + DS_ATIVO
      );

      if (res.status === 200) {
        return true;
      } else {
        console.error(res.data);
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  downloadPDF: async (CD_PRL) => {
    await api
      .get(VISUALIZAR_URL + '/' + CD_PRL, { responseType: 'blob' })
      .then((res) => {
        const contentDisposition = res.headers['content-disposition'];
        let fileName = 'file.pdf';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }
        fileDownload(res.data, fileName);
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          try {
            const { data } = error.response;
            // Read file
            const file = fileReader(data);
            // Parse content and retrieve 'message'
            const { message } = JSON.parse(file);
            mostraErro(message);
          } catch (readError) {
            // Show a dummy error if sth goes wrong while retrieving 'message'
            mostraErro('Algo deu errado ao baixar este arquivo');
          }
        } else {
          mostraErro('Algo deu errado ao baixar este arquivo');
        }
        return null;
      });
  },
  inserirLog: (usuario, nome) => {
    const params = {
      nr_cpfcnpj: apenasNumeros(usuario),
      nm_empresa: apenasNumeros(nome),
    };

    api
      .get(INSERIRLOG_URL, { params })
      .then((res) => {
        return null;
      })
      .catch((error) => {
        console.error(error);
      });
    return null;
  },
});
