import React, { useState, useContext, useEffect } from "react";
import Tabela from "../Components/ListaFaturasAbertas/Tabela";
import ModalSistemaBloqueado from "../Components/SistemaBloqueado/ModalSistemaBloqueado";
import { AuthContext } from "../Context/auth";
import { useApi } from "../Api/api.js";
import BlockUi from "@availity/block-ui";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { KTSVG } from "../../_metronic/helpers";
import { styled } from "@mui/material/styles";
import "./home.css";

const dayjs = require("dayjs");
dayjs().format();

const ColorButtonWhats = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#25D366"),
  backgroundColor: "#25D366",
  "&:hover": {
    backgroundColor: "#128C7E",
  },
  "&:active": {
    backgroundColor: "#25D366",
  },
}));

function Home() {
  const Alerta = withReactContent(Swal);

  const { usuario } = useContext(AuthContext);
  const [texto, setTexto] = useState("");
  const [loading, setLoading] = useState(false);
  const [faturas, setFaturas] = useState([]);
  const [bloquear, setBloquear] = useState(false);
  const [openModalBloqueado, setOpenModalBloqueado] = useState(false);

  const handleAbrirModalBloqueado = () => {
    setOpenModalBloqueado(true);
  };

  const handleFecharModalBloqueado = () => {
    setOpenModalBloqueado(false);
    // VerificaPagamentoPIX("I");
    // consulta();
  };

  const api = useApi();

  async function downloadPDF(event, cd_prl) {
    try {
      setTexto("");
      setLoading(true);

      await api.downloadPDF(cd_prl);

      setLoading(false);
    } catch (e) {
      if (e?.response?.status === 404) {
        setTexto(e?.response?.data?.error);
      } else {
        Alerta.fire({
          title: <p>Erro</p>,
          text: e,
          icon: "error",
        });
      }
      setLoading(false);
    }
  }

  // Função para ativar a consulta do pagamento
  async function VerificaPagamentoPIX(ds_ativo) {
    try {
      const result = await api.VerificaPagamentoPIX(ds_ativo);
      return result; // Adicione este retorno para obter o resultado
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  // Função para verificar débito
  async function verificarDebito() {
    try {
      const result = await api.verificaDebito(usuario.cpfcnpj);
      setBloquear(result); // Armazena o resultado no state bloquear
      if (result === true) {
        handleAbrirModalBloqueado();
      }
    } catch (error) {
      console.error(error);
      setBloquear(false); // Caso ocorra algum erro, considera como 'Em dia'
    }
  }

  async function consulta() {
    try {
      setTexto("");
      setFaturas([]);
      setLoading(true);

      const result = await api.consulta(usuario.cpfcnpj);

      if (result) {
        setFaturas(result);
      } else {
        Alerta.fire({
          title: <p>Atenção</p>,
          text: result,
          icon: "warning",
        });
      }

      setLoading(false);
    } catch (e) {
      //console.log(e);
      if (e?.response?.status === 404) {
        if (e?.response?.data?.error !== "Nenhuma fatura encontrada")
          setTexto(e?.response?.data?.error);
      } else {
        Alerta.fire({
          title: <p>Erro</p>,
          text: e,
          icon: "error",
        });
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    consulta();
    verificarDebito();
  }, []);

  return (
    <div>
      <BlockUi tag="div" blocking={loading}>
        <Tabela
          faturas={faturas}
          downloadPDF={downloadPDF}
          bloquear={bloquear}
          handleAbrirModalBloqueado={handleAbrirModalBloqueado}
        />

        {texto && (
          <div>
            <Typography variant="p" component="div">
              {texto}
            </Typography>
          </div>
        )}

        <div className="card card-xl-stretch mt-5">
          <div className="card-body py-3">
            <div className="d-flex align-items-center">
              <div className="row g-12">
                <div className="col-12">
                  <div className="d-flex align-items-center">
                    <Typography
                      sx={{ mt: 1 }}
                      variant="subtitle2"
                      component="p"
                    >
                      Caso precise falar com um atendente, envie uma mensagem
                      para o WhatsApp: (67) 98191-3166, clicando no botão
                      abaixo.
                    </Typography>
                  </div>
                  <div className="d-flex align-items-center">
                    <ColorButtonWhats
                      type="button"
                      className="btn btn-lg text-nowrap w-60 mt-3"
                      onClick={() => {
                        window.open(
                          "https://api.whatsapp.com/send?phone=5567981913166&text=",
                          "_blank"
                        );
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/communication/com012.svg"
                        className="svg-icon-1 svg-icon-secondary"
                      />
                      Enviar mensagem
                    </ColorButtonWhats>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalSistemaBloqueado
          faturas={faturas}
          openModalBloqueado={openModalBloqueado}
          handleFecharModalBloqueado={handleFecharModalBloqueado}
          VerificaPagamentoPIX={VerificaPagamentoPIX}
        />
      </BlockUi>
    </div>
  );
}

const PaginaHome = () => (
  <>
    <Home />
  </>
);

export default PaginaHome;
