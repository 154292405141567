/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from 'react-router-dom';
import Erro500 from './components/Erro500';
import Erro404 from './components/Erro404';
import ErrosLayout from './ErrosLayout';

function PaginaErros() {
  return (
    <Routes>
      <Route element={<ErrosLayout />}>
        <Route path='404' element={<Erro404 />} />
        <Route path='500' element={<Erro500 />} />
        <Route index element={<Erro404 />} />
      </Route>
    </Routes>
  );
}

export default PaginaErros;
