/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { KTSVG } from '../../../../helpers';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';
import { SidebarMenuItem } from './SidebarMenuItem';

const SidebarMenuMain = () => {
  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Fatura Fácil
          </span>
        </div>
      </div>

      <SidebarMenuItem
        to='/inicio'
        icon='/media/icons/duotune/general/gen001.svg'
        title='Inicio'
        fontIcon='bi-app-indicator'
      />
    </>
  );
};

export { SidebarMenuMain };
