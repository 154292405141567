import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { KTSVG } from "../../../_metronic/helpers";

const ColorButtonWhats = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#25D366"),
  backgroundColor: "#25D366",
  "&:hover": {
    backgroundColor: "#128C7E",
  },
  "&:active": {
    backgroundColor: "#25D366",
  },
}));

function ModalSistemaBloqueado(props) {
  const handleEnviarMensagem = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=5567981913166&text=",
      "_blank"
    );
    props.handleFecharModalBloqueado(); // Fecha o modal após abrir o WhatsApp
  };

  return (
    <Dialog
      keepMounted
      fullWidth={true}
      maxWidth={"md"}
      open={props.openModalBloqueado}
      onClose={props.handleFecharModalBloqueado}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <Typography
          variant="p"
          component="div"
          textAlign="center"
          style={{
            wordWrap: "break-word",
            width: "100%",
            maxWidth: "100vw",
            margin: "1px 0",
          }}
        >
          Sistema Bloqueado
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="subtitle1"
          component="p"
          textAlign="center"
          style={{
            wordWrap: "break-word",
            width: "100%",
            maxWidth: "100vw",
            margin: "10px 0",
          }}
        >
          Para liberação, efetue o pagamento da parcela mais antiga e envie o
          comprovante no WhatsApp (67) 98191-3166 ou aguarde a compensação do
          boleto.
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <ColorButtonWhats
            type="button"
            className="btn btn-lg text-nowrap w-60 mt-3"
            onClick={handleEnviarMensagem}
          >
            <KTSVG
              path="/media/icons/duotune/communication/com012.svg"
              className="svg-icon-1 svg-icon-secondary"
            />
            Enviar mensagem
          </ColorButtonWhats>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleFecharModalBloqueado} autoFocus>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalSistemaBloqueado;
