import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import React from 'react';
import RotasPrivadas from './RotasPrivadas';
import { useAuth } from '../app/Context/auth';
import Login from '../app/Login/login';
import Logout from '../app/Login/logout';
import PaginaErros from '../app/Erros/PaginaErros';
import App from '../app';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

function Rotas() {
  const { usuario } = useAuth();

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='erro/*' element={<PaginaErros />} />
          <Route path='logout' element={<Logout />} />
          <Route path='login' element={<Login />} />
          {usuario ? (
            <>
              <Route path='/*' element={<RotasPrivadas />} />
              <Route index element={<Navigate to='/inicio' />} />
            </>
          ) : (
            <>
              <Route path='login' element={<Login />} />
              <Route path='*' element={<Navigate to='/login' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Rotas;
