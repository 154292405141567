import { MenuItem } from './MenuItem';
import { MenuInnerWithSub } from './MenuInnerWithSub';

export function MenuInner() {
  return (
    <>
      <div className='menu-item'>
        <div className='menu-content'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Fatura Fácil
          </span>
        </div>
      </div>
      {/*} <MenuItem title='NFe' to='/notas/nfe' />
      <MenuItem title='NFCe' to='/notas/nfce' /> */}

      <MenuItem title='Inicio' to='/inicio' />

      {/*<MenuInnerWithSub
        title='Listagem de notas'
        to='/notas'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to='/notas/nfe' title='NFe' hasBullet={true} />
        <MenuItem to='/notas/nfce' title='NFCe' hasBullet={true} />
    </MenuInnerWithSub> */}
    </>
  );
}
