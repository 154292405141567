import { CopyToClipboard } from 'react-copy-to-clipboard';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { KTSVG } from '../../../_metronic/helpers';
import { styled } from '@mui/material/styles';
import './styles.css';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#ED3237'),
  backgroundColor: '#ED3237',
  '&:hover': {
    backgroundColor: '#ED5237',
  },
  '&:active': {
    backgroundColor: '#ED3237',
  },
}));

const dayjs = require('dayjs');
dayjs().format();

function DrawerFaturaAberta(props) {
  const fatura = props.fatura;

  const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);

  return (
    <>
      <Drawer
        anchor='right'
        variant='temporary'
        open={props.statusModal}
        onClose={props.handleFecharModal}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 400,
            height: '100%',
          },
        }}
      >
        <Box sx={{ width: 'auto' }} role='presentation'>
          <AppBar sx={{ position: 'relative' }} color='error'>
            <Toolbar>
              <Typography
                sx={{ ml: 2, flexGrow: 1 }}
                variant='h6'
                component='div'
              >
                Detalhes da fatura
              </Typography>
              <IconButton
                edge='start'
                color='inherit'
                onClick={props.handleFecharModal}
                aria-label='close'
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Card variant='outlined'>
            <Table sx={{ maxWidth: 350, margin: 3 }} size='small'>
              <TableBody>
                <TableRow>
                  <TableCell>Vencimento</TableCell>
                  <TableCell align='right'>
                    <Typography
                      sx={{ ml: 3 }}
                      variant='subtitle2'
                      component='p'
                    >
                      {dayjs(fatura.dt_venctobase).format('DD/MM/YYYY')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Valor</TableCell>
                  <TableCell align='right'>
                    <Typography
                      sx={{ ml: 3 }}
                      variant='subtitle2'
                      component='p'
                    >
                      {numberFormat(fatura.vl_total)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Observações</TableCell>
                  <TableCell align='right'>
                    <Typography
                      sx={{ ml: 3 }}
                      variant='subtitle2'
                      component='p'
                    >
                      {fatura.ds_obs}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography sx={{ ml: 3 }} variant='h6' component='p'>
              Boleto
            </Typography>
            <Typography sx={{ ml: 3 }} variant='subtitle1' gutterBottom>
              {fatura.ds_codigobarrasformat}
            </Typography>
            <div className='row'>
              <div className='col text-center'>
                <ColorButton
                  variant='contained'
                  type='button'
                  className='btn btn-lg text-nowrap w-60 m-3'
                >
                  <CopyToClipboard
                    text={fatura.ds_codigobarrasformat}
                    onCopy={props.mostrarSnackbar({
                      vertical: 'top',
                      horizontal: 'right',
                    })}
                  >
                    <span>Copiar código de barras</span>
                  </CopyToClipboard>
                </ColorButton>
              </div>
            </div>
            <div className='row'>
              <div className='col text-center'>
                <Tooltip title='Download do arquivo PDF do boleto'>
                  <ColorButton
                    type='button'
                    className='btn btn-lg text-nowrap w-60 m-3'
                    onClick={(event) => props.downloadPDF(event, fatura.cd_prl)}
                  >
                    <KTSVG
                      path='/media/icons/duotune/files/fil017.svg'
                      className='svg-icon-1 svg-icon-secondary'
                    />
                    Baixar Boleto
                  </ColorButton>
                </Tooltip>
              </div>
            </div>
          </Card>
        </Box>
      </Drawer>
    </>
  );
}

export default DrawerFaturaAberta;
