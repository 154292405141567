/* eslint-disable react/jsx-no-target-blank */
import { KTSVG } from '../../../helpers';
import { useState } from 'react';

const SidebarFooter = () => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const btnStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: isHover ? '#ED5237' : '#ED3237',
    color: isHover ? 'black' : 'white',
  };

  return (
    <div
      className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6'
      id='kt_app_sidebar_footer'
    >
      <a
        href='/logout'
        className='btn btn-flex flex-center btn-custom overflow-hidden text-nowrap px-0 h-40px w-100'
        data-bs-toggle='tooltip'
        data-bs-trigger='hover'
        data-bs-dismiss-='click'
        title='Sair'
        style={btnStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span className='btn-label'>Sair</span>
        <KTSVG
          path='/media/icons/duotune/arrows/arr096.svg'
          className='btn-icon svg-icon-2 m-0'
        />
      </a>
    </div>
  );
};

export { SidebarFooter };
