import React, { useContext, useEffect, useState } from "react";
import { LayoutSplashScreen } from "../../_metronic/layout/core";
import { useApi } from "../Api/api.js";

export const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthInit = ({ children }) => {
  const { logout, setUsuario } = useAuth();
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  useEffect(() => {
    const usuarioRecuperado = localStorage.getItem("usuario");

    if (usuarioRecuperado) {
      setUsuario(JSON.parse(usuarioRecuperado));
    }

    if (usuarioRecuperado) {
      //requestUser(auth.api_token);
      setShowSplashScreen(false);
    } else {
      logout();
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export const AuthProvider = ({ children }) => {
  const [usuario, setUsuario] = useState("");
  const api = useApi();
  const [carregando, setCarregando] = useState(true);
  const [msgErro, setMsgErro] = useState("");

  useEffect(() => {
    const usuarioRecuperado = localStorage.getItem("usuario");

    if (usuarioRecuperado) {
      setUsuario(JSON.parse(usuarioRecuperado));
    }

    setCarregando(false);
  }, []);

  const login = async (cpfCnpj: string, mostraErro) => {
    cpfCnpj = cpfCnpj.replace(/\D/gim, "");

    let usr = await api.login(cpfCnpj).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //setMsgErro(error.response.data);
        mostraErro(error.response.data.error);
      } else {
        console.log(error);
        mostraErro(error.message);
      }
      return false;
    });

    if (usr) {
      const usuarioEncontrado = {
        id: usr?.id,
        nome: usr?.nome,
        email: usr?.email,
        telefone: usr?.telefone,
        cpfcnpj: usr?.cpfcnpj,
      };
      setUsuario(usuarioEncontrado);
      localStorage.setItem("usuario", JSON.stringify(usuarioEncontrado));
      // inserirLog(usuarioEncontrado.cpfcnpj, usuarioEncontrado.nome);
      return true;
    }
    return false;
  };

  const inserirLog = async (cpfCnpj: string, nome: string) => {
    await api.inserirLog(cpfCnpj, nome);
  };

  const logout = async () => {
    setUsuario(null);
    localStorage.removeItem("usuario");
  };

  return (
    <AuthContext.Provider
      value={{
        logado: !!usuario,
        usuario,
        carregando,
        login,
        logout,
        msgErro,
        setUsuario,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
